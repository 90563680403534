import { Component, OnInit } from '@angular/core';
import { UserAction } from '@longnecktech/splash-commons-fe';
import { CollectibleGoalType } from 'src/app/shared/types/collectible-goal';
import { SessionService } from '@services/session.service';
import { emitIframeMessage } from '../../utils/common.utils';
import { take, map, filter, switchMap } from 'rxjs/operators';
import { CollectiblesService } from '@services/collectibles.service';
import { SpinService } from '@services/spin.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { delay, combineLatest } from 'rxjs';
import { ConfettiService } from '@services/confetti.service';

@UntilDestroy()
@Component({
  selector: 'app-winnings',
  templateUrl: './winnings.component.html',
  styleUrls: ['./winnings.component.scss'],
})
export class WinningsComponent implements OnInit {
  readonly types = CollectibleGoalType;
  collectibles$ = this.collectibleService.collectibles$.pipe(
    map((collectibles) =>
      collectibles.filter(
        (col) =>
          col.inThisSpinCollectedCount &&
          col.goal.type === CollectibleGoalType.COLLECTIBLE,
      ),
    ),
  );
  isMobile$ = this.session.isMobile$;
  game$ = this.session.game$;
  showCloseButton$ = this.game$.pipe(map((game) => !game!.hideCloseButton));
  awardAnimationPlayed$ = this.session.awardAnimationPlayed$;
  showNextSpinInfo$ = this.spinService.showNextSpinInfo$;
  spin$ = this.spinService.spin$.pipe(filter((spin) => !!spin));
  winnings$ = this.spin$.pipe(map((spins) => spins!.winnings));
  won$ = this.winnings$.pipe(map((winnings) => !!winnings!.length));

  upselling$ = this.session.game$.pipe(
    map((game) => {
      return [
        {
          img: game?.featuredProducts[0]?.asset.url,
          title: 'winnings.suggestedGame',
          game: game?.featuredProducts[0]?.name,
        },
      ].filter((u) => !!u.img);
    }),
  );

  constructor(
    private session: SessionService,
    private collectibleService: CollectiblesService,
    private spinService: SpinService,
    private confettiService: ConfettiService,
  ) {}

  ngOnInit() {
    this.initConfetti();
    this.initSpinInfo();
    this.awardAnimationPlayed$
      .pipe(
        take(1),
        filter((played) => !played),
        delay(1500),
      )
      .subscribe(() => {
        this.session.setAwardAnimation(true);
      });
  }

  dismiss() {
    this.confettiService.reset();
    this.spinService.setSpin(undefined);
    this.session.setShowWinnings(false);
    this.session.setAwardAnimation(false);
  }

  playNow() {
    emitIframeMessage('upsell-1');
    this.session
      .sendAction(UserAction.CLICK_CLAIM_PRIZE, {})
      .pipe(
        switchMap(() => this.session.game$),
        take(1),
        filter((game) => !!game?.ctaLink),
      )
      .subscribe((game) => {
        // make http call to track upsell
        (window.top || window).location.href = game!.ctaLink!;
      });
  }

  deposit() {
    emitIframeMessage('deposit');
    this.session
      .sendAction(UserAction.CLICK_DEPOSIT, {})
      .pipe(
        switchMap(() => this.session.game$),
        take(1),
        filter((game) => !!game?.depositLink),
      )
      .subscribe((game) => {
        (window.top || window).location.href = game!.depositLink!;
      });
  }

  close() {
    // TODO: if no game exists this is bad
    emitIframeMessage('close');
    this.session.game$
      .pipe(
        take(1),
        filter((game) => !!game?.closeLink),
      )
      .subscribe((game) => {
        console.log('closeLink', game!.closeLink);
        (window.top || window).location.href = game!.closeLink!;
      });
  }

  private initSpinInfo(): void {
    this.spinService.spinInfo$
      .pipe(
        take(1),
        filter((info) => !!info && info.nextSpinInSeconds > 0),
      )
      .subscribe(() => {
        this.spinService.updateShowNextSpinInfo(true);
      });
  }

  private initConfetti(): void {
    combineLatest([
      this.won$.pipe(take(1)),
      this.collectibles$.pipe(take(1)),
      this.session.awardAnimationPlayed$.pipe(take(1)),
    ])
      .pipe(
        filter(
          ([won, collectedCollectibles, awardAnimationPlayed]) =>
            (won || !!collectedCollectibles.length) && !awardAnimationPlayed,
        ),
      )
      .subscribe(() => {
        this.triggerConfetti(true);
      });
  }

  private triggerConfetti(big = false) {
    this.confettiService.shoot(50, 500);
    if (big) {
      this.confettiService.shoot(100, 1500);
      this.confettiService.shoot(70, 3000);
    }
  }
}
