import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '@services/session.service';
import { emitIframeMessage } from '../../utils/common.utils';
import { LinkMode } from '@longnecktech/splash-commons-fe';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  gameRulesLinkMode$ = this.session.game$.pipe(
    map((game) => game?.gameRulesLinkMode),
  );
  termsLinkMode$ = this.session.game$.pipe(map((game) => game?.termsLinkMode));
  faqLinkMode$ = this.session.game$.pipe(map((game) => game?.faqLinkMode));
  gameRulesLinkModeVisible$ = this.gameRulesLinkMode$.pipe(
    map((mode) => mode !== LinkMode.HIDDEN),
  );
  termsLinkModeVisible$ = this.termsLinkMode$.pipe(
    map((mode) => mode !== LinkMode.HIDDEN),
  );
  faqLinkModeVisible$ = this.faqLinkMode$.pipe(
    map((mode) => mode !== LinkMode.HIDDEN),
  );

  constructor(
    private session: SessionService,
    private router: Router,
  ) {}

  openGameRules(): void {
    this.gameRulesLinkMode$.pipe(take(1)).subscribe((mode) => {
      if (mode === LinkMode.EXTERNAL) {
        emitIframeMessage('game-rules');
      } else if (mode === LinkMode.INTERNAL) {
        this.router.navigate(['/info', 'game-rules'], {
          queryParamsHandling: 'preserve',
        });
      }
    });
  }

  openTerms(): void {
    this.termsLinkMode$.pipe(take(1)).subscribe((mode) => {
      if (mode === LinkMode.EXTERNAL) {
        emitIframeMessage('terms-conditions');
      } else if (mode === LinkMode.INTERNAL) {
        this.router.navigate(['/info', 'terms-conditions'], {
          queryParamsHandling: 'preserve',
        });
      }
    });
  }

  openFaq(): void {
    this.faqLinkMode$.pipe(take(1)).subscribe((mode) => {
      if (mode === LinkMode.EXTERNAL) {
        emitIframeMessage('faq');
      } else if (mode === LinkMode.INTERNAL) {
        this.router.navigate(['/info', 'faq'], {
          queryParamsHandling: 'preserve',
        });
      }
    });
  }
}
